export const Links = [
  {
    id: 2,
    text: 'About',
    url: '/#about',
  },
  {
    id: 3,
    text: 'Skills',
    url: '/#skills',
  },
  {
    id: 4,
    text: 'projects',
    url: '/#projects',
  },
  {
    id: 5,
    text: 'Blog',
    url: '/#blog',
  },
  {
    id: 6,
    text: 'Contact',
    url: '/#contact',
  },
];